.TeamDash{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-image: linear-gradient(to top, #2aabe4, #9dc8e4);
}

.EmptyTeam{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-image: linear-gradient(to top, #2aabe4, #9dc8e4);
}

.btn-holder{
    width: 75vw;
    margin-top: 20px;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-end;
	align-content: stretch;

}

.team-overview {
    width: 75vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    border-style: solid;
    border-color: #33393d;
    border-radius: 10px;
    background-color: whitesmoke;
    padding: 5px 10px;
}

.team-name {
    padding-bottom: 20px;
    font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 300;
}

.team-name-btns{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.team-stats {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
    align-content: stretch;
    font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 300;
}

.NewPlayer-team-view {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
	align-content: stretch;
}

.roster {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
    align-content: stretch;
    width: 70vw;
    border-style: solid;
    border-color: #33393d;
    border-radius: 10px;
    background-color: whitesmoke;
    padding: 10px 10px;
    margin-top: 20px;
}

.player-display {
    width: 98%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
    align-content: stretch;
    border-width: 1px;
    border-style: solid;
    border-color: #2aabe4;
    border-radius: 25px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    background-image: linear-gradient(to right, #9dc8e4, #2aabe4);
}

