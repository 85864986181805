.NewTeam{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-image: linear-gradient(to top, #2aabe4, #9dc8e4);
}

.new-team-name{
    margin-top: 80px;
    padding-left: 15px;
    padding-right: 15px;
    width: 30vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #9dc8e4;
    border-width: 1px;
    border-style: solid;
    border-color: whitesmoke;
    border-radius: 10px;
}