.PlayerStats{
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
    align-content: stretch;
    margin: 10px;
    border-style: solid;
    border-color: #33393d;
    border-radius: 10px;
    background-color: whitesmoke;
}

.stat-display{
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
    align-content: stretch;
    padding: 15px;
}