body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
  background-image: -webkit-linear-gradient(left, #2aabe4, #9dc8e4);
  background-image: linear-gradient(to right, #2aabe4, #9dc8e4);
  overflow: auto;
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

button {
  background-color: #F8A052;
  border-color: #F8A052;
  color: #211102;
  border-radius: 5px;
}

form {
  padding-bottom: 10px;
}

p {
  display: block;
  -webkit-margin-before: 0.5em;
          margin-block-start: 0.5em;
  -webkit-margin-after: 0.5em;
          margin-block-end: 0.5em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
}

.Login-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: -webkit-linear-gradient(left, #2aabe4, #9dc8e4);
    background-image: linear-gradient(to right, #2aabe4, #9dc8e4);
}

.Login {
    font-size: calc(10px + 2vmin);
    font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 300;
    color: white;
}

.Login-img {
    max-width: 600px;
    max-height: 238px;
    width: 60vw;
  }
.Header-wrapper {
    background-color: #33393D;
    /* overflow: hidden; */
    /* position: fixed;  */
    top: 0; 
    width: 100vw;
    /* height: 50px; */
    font-size: calc(10px + 2vmin);
    color: #000000;
}

.Header-content {
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	align-content: stretch;
    background-image: -webkit-linear-gradient(left, #33393D, #666b6e);
    background-image: linear-gradient(to right, #33393D, #666b6e);
    height: 100%;
}

.Header-user {
    width: 200px;
    height: 90%;
    font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 200;
}

.settings {
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
    align-content: stretch;
    margin-right: 20px;
}

.logo {
    width: 35%;
    height: 85%;
}

.Header-gear {
    width: 60px;
    height: 60px;
    /* background-color: #33393D; */
}

.Header-signout {
    font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 200;
}

.Header-signout, .Header-gear:hover{
    cursor: pointer;
}
.Dashboard{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-image: -webkit-linear-gradient(bottom, #2aabe4, #9dc8e4);
    background-image: linear-gradient(to top, #2aabe4, #9dc8e4);
}

.dash-wrapper {
    width: 75%;
    color: #33393d;
    padding-top: 40px;
}

.dash-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 200px;
    padding-left: 15px;
    margin-right: 200px;
    padding-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: calc(10px + 2vmin);
    font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 600;
}

.team-display {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #9dc8e4;
    margin-left: 200px;
    padding-left: 15px;
    margin-right: 200px;
    padding-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: whitesmoke;
    border-radius: 25px;
    font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 300;
}

.team-display:hover{
    cursor: pointer;
    opacity: 0.8;
    -webkit-transition: 0.3;
    transition: 0.3;
}
.NewPlayer {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
    align-content: stretch;
    margin-bottom: 20px;
}

.player-form{
    margin-bottom: 20px;
}

.player-display{
    margin-top: 20px;
}
.TeamDash{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-image: -webkit-linear-gradient(bottom, #2aabe4, #9dc8e4);
    background-image: linear-gradient(to top, #2aabe4, #9dc8e4);
}

.EmptyTeam{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-image: -webkit-linear-gradient(bottom, #2aabe4, #9dc8e4);
    background-image: linear-gradient(to top, #2aabe4, #9dc8e4);
}

.btn-holder{
    width: 75vw;
    margin-top: 20px;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-end;
	align-content: stretch;

}

.team-overview {
    width: 75vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    border-style: solid;
    border-color: #33393d;
    border-radius: 10px;
    background-color: whitesmoke;
    padding: 5px 10px;
}

.team-name {
    padding-bottom: 20px;
    font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 300;
}

.team-name-btns{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.team-stats {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
    align-content: stretch;
    font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 300;
}

.NewPlayer-team-view {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
	align-content: stretch;
}

.roster {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
    align-content: stretch;
    width: 70vw;
    border-style: solid;
    border-color: #33393d;
    border-radius: 10px;
    background-color: whitesmoke;
    padding: 10px 10px;
    margin-top: 20px;
}

.player-display {
    width: 98%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
    align-content: stretch;
    border-width: 1px;
    border-style: solid;
    border-color: #2aabe4;
    border-radius: 25px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    background-image: -webkit-linear-gradient(left, #9dc8e4, #2aabe4);
    background-image: linear-gradient(to right, #9dc8e4, #2aabe4);
}


.PlayerStats{
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
    align-content: stretch;
    margin: 10px;
    border-style: solid;
    border-color: #33393d;
    border-radius: 10px;
    background-color: whitesmoke;
}

.stat-display{
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
    align-content: stretch;
    padding: 15px;
}
.GTMaster{
    min-height: 100vh;
    background-image: -webkit-linear-gradient(bottom, #2aabe4, #9dc8e4);
    background-image: linear-gradient(to top, #2aabe4, #9dc8e4);
}

.GameTracker{
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    /* background-image: linear-gradient(to top, #2aabe4, #9dc8e4); */
}

/* .PlayerStats{
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
    align-content: stretch;
    margin: 10px;
    border-style: solid;
    border-color: #33393d;
    border-radius: 10px;
    background-color: whitesmoke;
} */

.NewGame{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-image: -webkit-linear-gradient(bottom, #2aabe4, #9dc8e4);
    background-image: linear-gradient(to top, #2aabe4, #9dc8e4);
}

.NewGame-input{
    margin-top: 50px;
}
.NewTeam{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-image: -webkit-linear-gradient(bottom, #2aabe4, #9dc8e4);
    background-image: linear-gradient(to top, #2aabe4, #9dc8e4);
}

.new-team-name{
    margin-top: 80px;
    padding-left: 15px;
    padding-right: 15px;
    width: 30vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #9dc8e4;
    border-width: 1px;
    border-style: solid;
    border-color: whitesmoke;
    border-radius: 10px;
}
.PlayerDetail{
    min-height: 100vh;
}

.back-btn{
    margin-top: 20px;
    margin-right: 200px;
    display: flex;
	flex-direction: row-reverse;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-end;
	align-content: stretch;
}

.PlayerSummary{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* width: 80vw; */
    margin-right: 300px;
    margin-left: 300px;
}

.stat-summary{
    border-style: solid;
    border-width: 1px;
    border-radius: 20px;
    border-color: #33393d;
    background-color: whitesmoke;
    margin-bottom: 40px;
}

.chart-wrapper{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: stretch;
}

.charts{
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
    background-color: whitesmoke;
    width: 90%;
}

.Settings{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-image: -webkit-linear-gradient(bottom, #2aabe4, #9dc8e4);
    background-image: linear-gradient(to top, #2aabe4, #9dc8e4);
}
