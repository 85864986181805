.PlayerDetail{
    min-height: 100vh;
}

.back-btn{
    margin-top: 20px;
    margin-right: 200px;
    display: flex;
	flex-direction: row-reverse;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-end;
	align-content: stretch;
}

.PlayerSummary{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* width: 80vw; */
    margin-right: 300px;
    margin-left: 300px;
}

.stat-summary{
    border-style: solid;
    border-width: 1px;
    border-radius: 20px;
    border-color: #33393d;
    background-color: whitesmoke;
    margin-bottom: 40px;
}

.chart-wrapper{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: stretch;
}

.charts{
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
    background-color: whitesmoke;
    width: 90%;
}