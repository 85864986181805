.GTMaster{
    min-height: 100vh;
    background-image: linear-gradient(to top, #2aabe4, #9dc8e4);
}

.GameTracker{
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    /* background-image: linear-gradient(to top, #2aabe4, #9dc8e4); */
}

/* .PlayerStats{
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
    align-content: stretch;
    margin: 10px;
    border-style: solid;
    border-color: #33393d;
    border-radius: 10px;
    background-color: whitesmoke;
} */
